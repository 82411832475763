<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  data() {
    return {
      themeConfigPage: this.$route?.meta?.modules[0] || "member_center"
    };
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
    }),
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);

  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}

.page {
  padding: 16px 12px 32px;
  margin-bottom: 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 32px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__button {
    padding: 12px;
  }
}

.custom-checkbox {
  padding-left: 1.5rem;
  margin-right: 1rem;

  .custom-control-input {
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 14px;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color) !important;
      border-radius: 4px;
      background: white;
      left: -1.5rem;
    }

    & + label::after {
      left: -1.5rem;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before,
    &:active + label:before {
      background: var(--liff-button-color) !important;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  }
}
</style>
